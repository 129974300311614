<template>
  <div>
    <el-form class="query-form" inline>
      <el-form-item label="地区">
        <el-cascader
          filterable
          size="small"
          :options="options_"
          v-model="selectedOptions"
          @change="handleChange"
          placeholder="请选择"
          :props="{ checkStrictly: true }"
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker
          v-model="time"
          type="date"
          size="small"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd hh:mm:ss"
          @change="changeTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          @click="search"
          @keydown.enter="search"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button size="small" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="main-content">
      <el-table :data="tableData" style="width: 100%" border stripe>
        <el-table-column prop="provinceName" label="省"> </el-table-column>
        <el-table-column prop="cityName" label="市"> </el-table-column>
        <el-table-column prop="areaName" label="区"> </el-table-column>
        <el-table-column prop="communityName" label="社区街道">
        </el-table-column>
        <el-table-column label="风险状态">
          <template slot-scope="scope">
            <el-button
              :type="scope.row.levelCodeDes == '低风险地区'?'info':scope.row.levelCodeDes == '中风险地区'?'warning':'danger'"
              size="small"
              round
              >{{scope.row.levelCodeDes}}</el-button
            >
            
          </template>
        </el-table-column>
        <el-table-column prop="createdTime" label="更新日期"> </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total,sizes,prev, pager, next"
        style="text-align: right; margin-top: 20px"
        @size-change="sizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        @current-change="pageCurrent"
        :page-size="query.ps"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { areaList } from "@/api/api";
import format from "@/mixins/format";
import page from "@/mixins/page";
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";
export default {
  data() {
    return {
      query: {
        ps: 10,
        pn: 1,
      },
      total: 0,
      tableData: [],
      options_: provinceAndCityData,
      selectedOptions: [],
      time: "",
      defaultDate:''
    };
  },
  mixins: [format, page],
  mounted() {
    this.time = this.getNowTime() + ' 00:00:00';
    this.query.startTime = this.time.substr(0,10) + " 00:00:00";
    this.query.endTime = this.time.substr(0,10) + " 23:59:59";
    this.getList();
  },
  methods: {
    getNowTime() {
      let now = new Date();
      let year = now.getFullYear(); //得到年份
      let month = now.getMonth(); //得到月份
      let date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      let defaultDate = `${year}-${month}-${date}`;
      return defaultDate;
    },
    getList() {
      areaList(this.query).then((res) => {
        this.total = res.data.data.total;
        this.tableData = res.data.data.list;
      });
    },
    handleChange(e) {
      this.query.cityName = null;
      this.query.provinceName = null;
      regionData.forEach((parent) => {
        if (parent.value == e[0]) {
          this.query.provinceName = parent.label.substr(
            0,
            parent.label.length - 1
          );
          parent.children.forEach((item) => {
            if (item.value == e[1]) {
              if (item.label == "市辖区") {
                this.query.cityName = "";
              } else {
                this.query.cityName = item.label.substr(
                  0,
                  item.label.length - 1
                );
              }
            }
          });
        }
      });
    },
    changeTime(e) {
      this.query.startTime = e?(e.substr(0, 10) + " 00:00:00"):null;
      this.query.endTime = e?(e.substr(0, 10) + " 23:59:59"):null;
    },
  },
};
</script> 

<style scoped>
.query-form {
  margin-top: 20px;
  background-color: #f2f2f2;
  padding: 25px 20px 0;
}
</style>